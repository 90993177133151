var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-responsive mb-0"},[_c('table',{staticClass:"table table-hover table-centered table-nowrap"},[_vm._m(0),_c('tbody',_vm._l((_vm.transactions),function(data){return _c('tr',{key:data.id},[_c('th',{attrs:{"scope":"row"}},[_vm._v(_vm._s(data.id))]),_c('td',[_c('div',[_c('img',{staticClass:"avatar-xs rounded-circle me-2",attrs:{"src":`${data.image}`,"alt":""}}),_vm._v(" "+_vm._s(data.name)+" ")])]),_c('td',[_vm._v(_vm._s(data.date))]),_c('td',[_vm._v(_vm._s(data.amount))]),_c('td',[_c('span',{staticClass:"badge",class:{
            'bg-danger': data.status === 'Cancel',
            'bg-warning': data.status === 'Pending',
            'bg-success': data.status === 'Delivered'
        }},[_vm._v(_vm._s(data.status))])]),_vm._m(1,true)])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("(#) Id")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Name")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Date")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Amount")]),_c('th',{attrs:{"scope":"col","colspan":"2"}},[_vm._v("Status")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',[_c('a',{staticClass:"btn btn-primary btn-sm",attrs:{"href":"#"}},[_vm._v("Edit")])])])
}]

export { render, staticRenderFns }