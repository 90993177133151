<script>
/**
 * Gamification component
 */
export default {
  props: {
    gamifications: {
      type: Array,
      default: function() {
        return [];
      }
    }
  }
};
</script>

<template>
  <div class="table-responsive mb-0">
    <table class="table table-hover table-centered table-nowrap">
      <thead>
        <tr>
          <th scope="col">(#) Id</th>
          <th scope="col">Nome</th>
          <th scope="col">Pontuação</th>
          <th scope="col">Escudo</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="data in gamifications" :key="data.id">
          <th scope="row">{{data.id}}</th>
          <td>
            <div>
              {{data.name}}
            </div>
          </td>
          <td>{{data.amount}}</td>
          <td>
            <img :src="`${data.shield}`" alt class="avatar-xs rounded-circle me-2" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>